import React, { Component, Suspense } from 'react'
import { HashRouter, BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { Helmet } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Page404 = React.lazy(() => import('./views/auth/Page404'))
// const Home = React.lazy(() => import('./views/home/Home'))

class App extends Component {
  constructor(props) {
    super(props)
    this.state = { 
      openPopup: window.location.href === `https://pkit.kr/event` ? false : true,
      initialData: props.initialData || null, // eslint-disable-line react/prop-types
    }
  }

  render() {
    const queryClient = new QueryClient({defaultOptions:{queries:{retry:1,refetchOnWindowFocus:false,}}})

    const { openPopup, initialData } = this.state

    return (
      <React.Fragment>
         <Helmet defer={false}>
          <title>PickIT</title>
          <meta
            name="description"
            content="IT 교육은 처음인데, 뭐부터 들어야할지 고민이라면? PickIT에서 나에게 딱 맞는 교육을 추천받으세요. 내 관심사에 맞는 부트캠프 교육 추천부터 개발자 직무백서와 테스트를 통해 어떤 직무가 어울리는지 알아보고, 다른 사람들과 나누는 교육 후기와 취업정보까지!"
          />
        </Helmet>
        <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Suspense>
            <Routes>
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route path="*" name="Main" element={<DefaultLayout hideFooter={false}/>} />
            </Routes>
          </Suspense>
          {/* {
        openPopup ?
        <div style={{zIndex: '99999999', width: '100vw', height: '100vh', position: 'fixed', top: 0, left: 0, backgroundColor: 'rgba(0, 0, 0, 0.7)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <div style={{width: '600px', height: 'auto',position: 'relative' }} onClick={() => {this.setState({openPopup: false}); window.location.href="/event"}}>
          <div onClick={(e) => {e.stopPropagation(); this.setState({openPopup: false})}} style={{width: '15px', height: '15px', cursor: 'pointer', position: 'absolute', right: '5px', top: '5px'}}>
            <img src="/img/i_close.png"/>
            </div>
        <img style={{width: '100%', height: '100%', objectFit: 'contain'}} src="/img/renewal_notice.jpeg"/>
        </div>
      </div>
        : null
      } */}
        </BrowserRouter>
        </QueryClientProvider>
      </React.Fragment>

    )
  }
}

export default App

import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import { HelmetProvider } from 'react-helmet-async';
import { hydrateRoot } from 'react-dom/client'

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <Provider store={store}>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </Provider>,
  document.getElementById('root'),
)

// SSR + hydration
// const domNode = document.getElementById("root")
// SSR initial data
// const initialData = window.__INITIAL_DATA__

// hydrateRoot(
//   domNode,
//   <Provider store={store}>
//     <HelmetProvider>
//         <App initialData={initialData}/>
//     </HelmetProvider>
//   </Provider>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
